<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 측정년도 -->
          <c-datepicker
            name="measYear"
            type="year"
            :range="true"
            label="측정년도"
            defaultStart="-3y"
            defaultEnd="0y"
            v-model="searchParam.year"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="WORK_MEAS_QUARTER_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="measQuarter"
            label="분기"
            v-model="searchParam.measQuarter"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            :disabled="true"
            :afterIcon="[
              { name: 'search', click: true, callbackName: 'searchHazard', color: 'teal' },
              { name: 'close', click: true, callbackName: 'removeHazard', color: 'red' }
            ]"
            label="유해인자"
            name="hazardName"
            v-model="searchParam.hazardName"
            @searchHazard="openHazard"
            @removeHazard="removeHazard">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :comboItems="workFlagItems"
            label="특수건강검진관련여부"
            itemText="codeName"
            itemValue="code"
            name="speHealthFlag"
            type="search"
            v-model="searchParam.speHealthFlag">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="WORK_EXPOSURE_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="exposureFlag"
            label="노출기준초과여부"
            v-model="searchParam.exposureFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-tab
      class="q-mt-sm"
      type="tabcard"
      align="left"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab" >
        <component
          :is="tab.component"
          :searchParam.sync="searchParam"
          :tabParam="tabParam"
          :count="count"
        />
      </template>
    </c-tab>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'harmful-factors',
  data() {
    return {
      tab: 'resultInfo1',
      tabItems: [
        { name: 'resultInfo1', icon: 'groups', label: '화학적인자', component: () => import(`${'./workMeasureResultFactorTab.vue'}`) },
        { name: 'resultInfo2', icon: 'groups', label: '물리적인자', component: () => import(`${'./workMeasureResultFactorTab.vue'}`) },
        { name: 'resultInfo3', icon: 'groups', label: '분진', component: () => import(`${'./workMeasureResultFactorTab.vue'}`) },
      ],
      tabParam: {
        title: '화학적인자',
      },
      searchParam: {
        plantCd: '',
        measQuarter: null,
        measYear: '',
        startYmd: '',
        endYmd: '',
        speHealthFlag: null,
        exposureFlag: null,
        hazardClassFstCd: 'HCF0000005',

        hazardCd: '',
        hazardName: '',
        year: ['', ''],
      },
      workFlagItems: [
        { code: 'Y', codeName: '관련' },
        { code: 'N', codeName: '미관련' },
      ],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      count: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sai.workmeas.result.factor.url;
      // this.searchParam.year = [this.$comm.getPrevYear() - 3, this.$comm.getThisYear()];
    },
    tabClick(result) {
      this.count++;
      this.searchParam.hazardCd = null;
      this.searchParam.hazardName = null;
      if (result.name === 'resultInfo1') {
        this.tabParam.title = '화학적인자';
        this.searchParam.hazardClassFstCd = 'HCF0000005';
      } else if (result.name === 'resultInfo2') {
        this.tabParam.title = '물리적인자';
        this.searchParam.hazardClassFstCd = 'HCF0000001';
      } else {
        this.tabParam.title = '분진';
        this.searchParam.hazardClassFstCd = 'HCF0000010';
      }
    },
    openHazard() {
      this.popupOptions.title = this.tabParam.title + ' 검색'; // 유해인자 검색
      this.popupOptions.param = {
        type: 'single',
        hazardClassFstCd: this.searchParam.hazardClassFstCd,
        workEnvFlag: 'Y',
      };
      this.popupOptions.target = () => import(`${'./harmfulFactorsPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPopup;
    },
    closeHazardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.searchParam.hazardCd = data[0].hazardCd;
        this.searchParam.hazardName = data[0].hazardClassSecName + '/' + data[0].hazardName;
      }
    },
    removeHazard() {
      this.searchParam.hazardCd = '';
      this.searchParam.hazardName = '';
    },
  }
};
</script>
